.form {
  width: 100%;

  p {
    color: var(--dark-text-color);
    font-size: 14px;
  }

}

.disabled {
  opacity: .7;
  pointer-events: none;
}

.row {
  display: flex;
  gap: 1rem;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &:not(:first-child) {
    margin-top: 2rem;
  }

  .heading {
    margin-bottom: 10px;
    color: var(--dark-text-color);
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
  }
}

// platform input
.platform-input{
  display: flex;
  gap: 10px;
  align-items: baseline;
  button {
    min-height: 35px;
    padding: 0 16px;
  }
}
.days-input {
  display: flex;
  align-items: baseline;
  gap: 10px;
  button {
    min-height: 35px;
    min-width: 8rem;
    padding: 0 16px;
  }
  .dropdown-wrapper {
    min-width: 40%;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 1rem;
  li {
    display: flex;
    align-items: center;
    gap: 20px;
    img {
      height: 15px;
      &:hover {
        ~* {
          opacity: .5;
        }
      }
    }
    .platform {
      font-weight: 700;
    }
  }
}

// category modal
.category-input {
  width: 70%;
}

.edit-delete-category {
  display: flex;
  flex-direction: column;
  gap: 10px;
}



