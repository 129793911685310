$primary-card-color: #FFEAEA;

.priority-card {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: .3s ease;
  &:hover {
   transform: scale(1.02);
  }
}
.icon-holder {
  position: relative;
  width: 30%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: $primary-card-color;
  box-shadow: 2px 1px 4px 0px rgba(0, 0, 0, 0.15);
  top: calc(var(--icon-height) / 2);
  img {
    position: absolute;
    width: 85%;
    height: 85%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;

  }
}
.card-body {
  position: relative;
  z-index: 2;
  width: 100%;
  aspect-ratio: 7/5;
  background: gray;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  background: $primary-card-color;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.08);
  flex-grow: 1;
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 93%;
    white-space: pre-line;
    text-align: center;
    font-weight: 800;
    color: var(--dark-text-color);
    font-size: clamp(12px, 1.15vw, 20px);
  }
}

@media screen and (max-width: 1024px){
  .card-body {
    aspect-ratio: unset;
    padding: 5rem 10px;
    p {
      position: relative;
      width: 100%;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 640px) {
  .icon-holder {
    min-width:60px;
  }
  .card-body {
    padding: 30% 5px;
    p {
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 400px) {
  .icon-holder {
    min-width: unset;
    max-width: unset;
    width: 80px;
  }
  .card-body {
    padding: 25% 10px;
    p {
      font-size: 14px;
    }
  }
}