.wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .form {
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    width: 300px;
    align-items: center;
    gap: 30px;
  }
  .katai-logo {
    width: 100%;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    width: 100%;
    input {
      width: 100%;
      background: none;
      outline: none;
      border: none;
      color: var(--light-text-color);
      border-bottom: 1px solid rgba(252, 243, 243, 0.70);
      transition: .3s ease;
      padding: 0 1rem;
      height: 35px;
      font-family: "Lato", sans-serif;
      &:focus, &.valid {
        border-bottom: 1px solid rgba(252, 243, 243, 1);
      }
      &::placeholder {
        color: rgba(252, 243, 243, 0.70);
      }
    }
    .submit-button {
      margin-top: 10px;
      width: fit-content;
      padding: 0 1rem;
      border: 1px solid var(--light-text-color);
      font-family: "Lato", sans-serif;
      color: var(--light-text-color);
      transition: .3s ease;
      cursor: pointer;
      font-weight: 700;
      white-space: nowrap;
      &.invalid {
        opacity: .7;
        pointer-events: none;
      }
    }
  }
}