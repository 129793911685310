.title {
  font-size: 40px;
  font-family: "Playfair", serif;
  cursor: pointer;
}

.description {
  font-size: 16px;
}

.wrapper {
  background: var(--navbar-color);
  border-radius: 3px;
  position: relative;
  padding-bottom: 70%;
  width: 100%;

  .content-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1.3rem;
    display: flex;
    gap: 2rem;
  }

  .image-wrapper {
    height: 100%;
    min-width: 50%;
    max-width: 50%;
    border-radius: 3px;
    background: #2c2c2c;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    .image-holder {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

  }

  .info-holder {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .description-holder {
    overflow: auto;
    margin-top: 1rem;
    flex: 1 1 auto;
  }

  .socials-row {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 7px;

    a {
      height: #{"max(25px, 2.2rem)"};
      display: flex;
      transition: .3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    padding-bottom: 0;

    .content-holder {
      position: relative;
      flex-direction: column;
    }

    .image-wrapper {
      height: 0;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      padding-bottom: 110%;
      overflow: hidden; /* Ensure images are contained within the container */
      position: relative;

      .image-holder {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }


    .socials-row {
      margin-top: 3rem;
    }
  }
}

@media screen and (max-width: 1550px) {
  .description {
    font-size: 14px;
  }
}

// loading
.holder {
  position: relative;
  width: fit-content;
  overflow: hidden;
  border-radius: 2px;
  background: #2c2c2c;

  h2, p {
    color: #2c2c2c;
  }
}

.description-holder {
  &.loading {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.socials-row {
  .holder {
    background: unset;

    .icon {
      height: max(25px, 2.2rem);
      width: max(25px, 2.2rem);
      background: #2c2c2c;
    }
  }
}