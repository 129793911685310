.carousel-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .slides-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    transition: .7s ease;
    .slide-holder {
      min-width: 100%;
      height: 100%;
      position: relative;
      img, video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(.8);
      }
    }
  }
}

.slide-holder {
  position: relative;
  &::before {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 70%;
    content: "";
    z-index: 1;
    display: block;
    background: linear-gradient(91deg, #121212 -10%, rgba(18, 18, 18, 0.33) 67.2%, rgba(18, 18, 18, 0.00) 94.5%);
  }
}

.carousel-wrapper {
  position: relative;
  .slides-indicator{
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 2;
  }
}

.slide-content-holder{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  justify-content: center;
  .text-holder {
    position: relative;
    z-index: 2;
   .main-desc {
     width: 37rem
   }
  }
}

@media screen and (max-width: 1024px){
  .slide-content-holder{
    .text-holder {
      .main-desc {
        width: #{"min(75%, 35rem)"};
      }
    }
  }
}