.file-input {
  display: none ;
}
.button-holder {
display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  opacity: .7;
  transition: .3s ease;
  button {
    min-height: 50px;
    min-width: 50px;
    max-width: 50px;
    border-radius: 50%;
    background: var(--dark-text-color);
      color: var(--light-text-color);
    font-size: 26px;
  }
  p {
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &:hover {
    opacity: 1;
  }
  &.active {
    opacity: 1;
  }
}