.title {
  font-size: 2rem;
  font-weight: 700;
  font-family: "Playfair", serif;
  color: var(--dark-text-color)
}

.description {
  font-size: 14px;
  color: var(--dark-text-color);
}

button {
  transition: .3s ease;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.invalid {
      opacity: .7;
      pointer-events: none;
  }
}

.cancel-button {
  border: 1px solid var(--main-red-color);

  p {
    color: var(--main-red-color)
  }

  &:hover {
    box-shadow: 0 0 3px var(--main-red-color);
  }
}

.delete-button {
  background: var(--main-red-color);
  &:hover {
    box-shadow: 0 0 3px rgba(52, 15, 15, 1);
  }
}

.confirm-button {
  background: rgba(52, 15, 15, 1);

  p {
    color: var(--light-text-color) !important;
  }

  &:hover {
    box-shadow: 0 0 3px rgba(52, 15, 15, 1);
  }
}

.content-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 70vh;
  overflow: auto;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 3rem;

}

.row {
  display: flex;
  gap: 1rem;
}
