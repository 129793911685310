.coaches {
  padding-bottom: 6rem;
  min-height: 40vh;
}
.cards-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  .card-holder {
    cursor: pointer;
    display: flex;
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .cards-grid {
    grid-template-columns: 1fr;
  }
}
