.welcome-section {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    height: 150px;
    width: 100%;
    background: linear-gradient(180deg, rgba(18, 18, 18, 0.00) 0%, #121212 47.92%);
  }
}

.about-us {
  padding: 8rem 0;

  .section-content {
    flex-direction: row;
    gap: 7rem;
  }

  .images-part {
    min-width: 50%;
    display: flex;
    gap: 1.3rem;

    .image-holder {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: fit-content;
      aspect-ratio: 9/13;
      background: #1c1c1c;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:nth-child(2) {
        margin-top: 7rem;
      }
    }
  }

  .text-part {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.priorities {
  padding: 10rem 0 20rem;
  background: var(--light-bg-color);
  position: relative;

  .priorities-bg-wrapper {
    position: absolute;
    top: 50%;
    right: 10%;
    height: 85%;
    transform: translateY(-50%);

    .priorities-bg {
      height: 100%;
    }
  }

  p {
    color: var(--dark-text-color);
  }

  .description {
    max-width: 40rem;
  }

  .priorities-holder {
    width: 100%;
    display: flex;
    gap: clamp(5px, .7rem, 10px);
    justify-content: center;
    margin-top: 8rem;

    .card-holder {
      width: 20%;
    }
  }
}

.find-us {
  padding: 4rem 0 8rem;

  .map-holder {
    margin-top: 3rem;
    position: relative;
    aspect-ratio: 16/7;
    overflow: hidden;

    .map-container {
      width: 100%;
      height: 100%;
    }
  }
}

.gallery {
  background: var(--light-bg-color);
  padding: 5rem 0 8rem;

  p {
    color: var(--dark-text-color);
    max-width: 38rem;
  }

  .images-holder {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    gap: .7rem;
    margin-top: 10rem;

    .col {
      display: flex;
      flex-direction: column;
      gap: .7rem;

      &:nth-child(2) {
        margin-top: -3rem;
      }

      &:nth-child(3) {
        margin-top: 3rem;
      }
    }

    .image-holder {
      background: #D9D9D9;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      width: 100%;
      height: 100%;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  .priorities {
    .priorities-bg-wrapper {
      height: 75%;
      right: 5%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .welcome-section {
    height: 50rem;
  }
  .about-us {
    .section-content {
      flex-direction: column;
      .images-part {
        order: 2;
      }
    }

    .text-part {
      p {
        width: 40rem;
      }
    }
  }
  .priorities {
    padding: 4rem 0 8rem;

    .priorities-bg-wrapper {
      display: none;
    }

    .priorities-holder {
      flex-wrap: wrap;

      .card-holder {
        width: 33.3333%;
      }
    }
  }
  .find-us {
    .map-holder {
      aspect-ratio: 16/9;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 640px) {
  .welcome-section {
    height: #{"min(80rem, 90vh)"};
  }
  .about-us {
    .text-part {
      p {
        width: 100%;
      }
    }
  }
  .priorities {
    .priorities-holder {
      margin-top: 3rem;
      gap: 10px;

      .card-holder {
        width: calc(50% - 5px);
      }
    }
  }
  .find-us {
    padding: 4rem 0 6rem;
    .map-holder {
      aspect-ratio: unset;
      height: 500px;
    }
  }
  .gallery {
    padding: 4rem 0 6rem;
    .images-holder {
      display: none;
    }
    .gallery-holder {
      margin-top: 4rem;
    }
  }
}

@media screen and (max-width: 450px) {
  .about-us {
    .section-content {
      gap: 4.5rem;
    }

    .images-part {
      flex-direction: column;
      gap: 2rem;

      .image-holder {
        width: 90%;

        &:nth-child(2) {
          margin-top: 0;
          align-self: flex-end;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .priorities {
    .priorities-holder {
      margin-top: 0;

      .card-holder {
        width: 100%;
      }
    }
  }
}
