.div {
  opacity: 0;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.fade-in-up {
    animation-name: fadeInUp;
}
.fade-in-down {
  animation-name: fadeInDown;
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(2rem);
}
  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}