.svg {
  width: clamp(30px, 5.7vw,110px);
}
@media screen and (max-width: 1024px){
  .svg {
    width: 7rem;
  }
}
@media screen and (max-width: 450px){
  .svg {
    width: 5.5rem;
  }
}