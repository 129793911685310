.footer-heading {
  font-size: #{"max(20px,1.5rem)"};
}

.footer-text, a, li {
  font-size: #{"max(14px,0.875rem)"};
}

.footer-holder {
  background: var(--dark-bg-color);
  padding: 3.5rem 0;

  // dark mode setup
  p, li, a {
    font-family: "Lato", sans-serif;
    font-weight: 400;
  }

  h2, p, li, a {
    color: var(--light-text-color);
  }

  // light mode setup
  &.light {
    background: var(--light-bg-color);

    h2, p, li, a {
      color: var(--dark-text-color);
    }

    .info-holder {
      a, li {
        &:hover {
          text-shadow: 0 0 2px var(--dark-text-color);
        }
      }

      .col {
        &:nth-child(2) {
          border-left: 1px solid var(--dark-text-color);
          border-right: 1px solid var(--dark-text-color);
        }
      }
    }

    .copyright {
      border-top: 1px solid var(--dark-text-color);
    }
  }

  .icons-row {
    display: flex;
    margin-top: 1rem;
    gap: #{"max(4px,.5rem)"};

    .icon {
      width: #{"max(25px, 1.8rem)"};
      transition: .3s ease;

      &:hover {
        transform: scale(1.07);
      }
    }
  }

  .info-holder {
    display: grid;
    margin: 3rem 0;
    grid-template-columns: repeat(3, min-content);

    a, li {
      transition: .3s ease;

      &:hover {
        text-shadow: 0 0 3px var(--light-text-color);
      }
    }

    .col {
      padding: 0 8rem 0 5rem;
      display: flex;
      align-items: center;

      &:first-child {
        padding: 0 5rem 0 0;
      }

      &:nth-child(2) {
        border-left: 1px solid var(--light-text-color);
        border-right: 1px solid var(--light-text-color);
      }
    }

    .navigation {
      ul {
        display: flex;
        flex-direction: column;
        gap: .9rem;

        li {
          list-style-type: none;
          cursor: pointer;
        }
      }

      .logo {
        display: none;
      }
    }

    .contact-information {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: .9rem;

      .group {
        display: flex;
        flex-direction: column;
      }
    }

    .logo-section {
      img {
        width: 7rem;
      }
    }
  }

  .copyright {
    padding-top: 1.2rem;
    border-top: 1px solid var(--light-text-color);

    p, a {
      color: #494949;
    }

    a {
      transition: .3s ease;

      &:hover {
        text-shadow: 0 0 2px #494949;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .footer-holder {
    padding: 3.5rem 0 10px;

    .info-holder {
      display: flex;
      flex-direction: column;

      .col {
        padding: 0;
        width: fit-content;

        &:nth-child(1) {
          padding-bottom: 3rem;
        }

        &:nth-child(2) {
          border: 0;
          padding-top: 3rem;
        }
      }

      .navigation {
        position: relative;
        width: 100%;

        .logo {
          display: block;
          position: absolute;
          top: 50%;
          right: 15%;
          transform: translateY(-55%);
          height: 120%;
        }

        &::after {
          content: "";
          display: block;
          width: 13rem;
          height: 1px;
          background: var(--light-text-color);
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      .logo-section {
        display: none;
      }

    }

    .copyright {
      border-top: 0;
      display: flex;
      justify-content: center;
    }

    &.light {
      .info-holder {
        .col {
          &:nth-child(2) {
            border: 0;
          }
        }

        .navigation {
          &::after {
            background: var(--dark-text-color);
          }
        }
      }
      .copyright {
        border: 0;
      }
    }
  }
}