.heading-holder {
  width: fit-content;
  display: flex;
  flex-direction: column;
.divider {
  width: 100%;
  height: 1px;
  background: var(--light-text-color);
  margin: .6rem 0 1.2rem;
}
  &.centered {
    margin: 0 auto;
    align-items: center;
  }
}
