.wrapper {
  position: relative;
  background: var(--navbar-color);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  border-radius: 3px;
  overflow: hidden;
  transition: .5s ease;
  cursor: pointer;

  .icon {
    height: 45%;
    position: absolute;
    top: 50%;
    left: 0.85rem;
    transform: translateY(-50%);
  }

  input {
    font-family: "Lato", sans-serif;
    background: unset;
    border: unset;
    color: var(--light-text-color);
    font-weight: 700;
    text-align: right;
    flex: 1 1 auto;
    overflow: hidden;
    transition: .3s ease;
    max-width: 0;
    opacity: 0;

    &::placeholder {
      color: #434343;
    }

    &:focus {
      outline: unset;
    }
  }

  &.opened {
    width: 20rem;
    min-width: 20rem;
    input {
      max-width: calc(100% - 3.5rem);
      opacity: 1;
    }
  }
}