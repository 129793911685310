.wrapper {
  width: #{"max(110px, 9rem)"};
  position: relative;
  background: var(--navbar-color);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .5s ease;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
  }

  .placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    color: var(--light-text-color);
    transition: 0.5s ease;

    p {
      font-weight: 700;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .items-list {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    background: var(--navbar-color);
    border-top: 1px solid rgba(252,243,243,0.1);
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: .5s ease;
    pointer-events: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 9;

    .scroll-holder {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;

      .item {
        padding: 5px 1rem;
        display: flex;
        width: 100%;
        gap: 10px;
        align-items: center;
        transition: .3s ease;
        cursor: pointer;

        p {
          font-size: 14px;
        }

        &:hover {
          background: #2c2c2c;
        }

        .checkbox {
          min-width: 12px;
          min-height: 12px;
          max-width: 12px;
          max-height: 12px;
          border: 1px solid var(--light-text-color);
          position: relative;
          &.checked {
            &::before, &::after {
              content: "";
              display: block;
              width: 100%;
              height: 1px;
              background: var(--light-text-color);
              position: absolute;
              top: 50%;
              left: 50%;
            }
            &:before {
              transform: translate(-50%, -50%) rotate(-45deg);
            }
            &:after {
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }
        }
      }
    }
  }

  .arrow {
    transition: .3s ease;
    margin-left: 10px;
    height: .5rem;
  }

  &.opened {
    width: 20rem;

    .arrow {
      transform: rotate(180deg);
    }

    .items-list {
      opacity: 1;
      height: 150px;
      pointer-events: auto;
    }
  }

  &.filter-active {
    width: 20rem;
  }

  &.modal {
    min-width: unset;
    width: 100%;
    background: unset;
    flex-direction: column;
    height: fit-content;
    padding: 0;
    .placeholder {
      height: 40px;
      border-bottom: 1px solid rgba(52, 15, 15, 0.40);
      padding: 0 1rem;
      p {
        color: rgba(52, 15, 15, 0.40);
        font-size: 14px;
      }
    }
    .items-list {
      background: unset;
        position: relative;
      .item {
        &:not(:last-child){
          border-bottom: 1px solid rgba(52, 15, 15, 0.40);
        }
        &:hover {
          background: #d6d6d6;
        }
        p {
          color: var(--dark-text-color)
        }
        .checkbox {
          border: 1px solid var(--dark-text-color);
          &.checked {
            &::before, &::after {
              background: var(--dark-text-color);
            }
          }
        }
      }
    }
    &.opened {
      .items-list {
      }
    }
    &.filter-active {
      .placeholder {
        border-bottom: 1px solid rgba(52, 15, 15, 1);
        p {
          color: rgba(52, 15, 15, 1);
        }
      }
    }
  }
}

@media screen and (max-width: 1024px){
  .wrapper {
    min-width: #{"max(110px, 9rem)"};
    flex-direction: column;
    justify-content: flex-start;

    .placeholder {
      min-height: 40px;
      max-height: 40px;
    }
    .items-list {
      position: relative;
      top: unset;
      width: 100%;
    }

    &.filter-active {
      min-width: #{"max(170px, 9rem)"};
    }
    &.opened {
      height: 190px;
      min-width: #{"max(170px, 9rem)"};
      .items-list {
        opacity: 1;
        min-height: 150px;
        pointer-events: auto;
        width: calc(100% + 2rem);
      }
    }

  }
}