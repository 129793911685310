.gallery-holder {
  width: 100%;
  aspect-ratio: 9/13;
  .scroll-holder {
    position: relative;
    height: 100%;
    width: calc(100% + 32px);
    left: -16px;
    display: flex;
    align-items: center;
    transition: .5s ease;
    overflow: auto;
    gap: 5px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    scroll-snap-type: x mandatory;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .image-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    transition: .3s ease;
    min-width: calc(100% - 32px);
    max-width: 750px;
    position: relative;
    scroll-snap-align: center;
    height: 100%;
    overflow: hidden;

    &:first-child {
      margin-left: 16px;
    }
    &:last-child {
      margin-right: 16px;
    }

    .image-content {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 92%;
      display: flex;
      transform: translateY(-50%);
      justify-content: center;
      align-items: center;
      transition: .3s ease;
      background: #D9D9D9;
      img {
        position: absolute;
        width: 97%;
        height: 97%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: contain;
        transition: .3s ease;
      }
    }

    &.active {
      .image-content {
      height: 100%;
      img {
        transform: translate(-50%, -50%) scale(1.2);

      }
      }
    }
  }
}