.wrapper {
  padding: 1.6rem;
  background: rgba(34,34,34,.8);
  border-radius: 4rem;
  display: flex;
  gap: 16px;
  .dot {
    width: 8px;
    height: 8px;
    background: rgba(252, 243, 243, 0.70);
    border-radius: 10px;
    transition: .5s ease;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    .inner-bar {
      width: 0;
      height: 100%;
      background: var(--light-text-color);
      transition: .3s ease;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:hover {
      background: rgba(252, 243, 243, .3);
    }
    &.active {
      opacity: 1;
      width: 4rem;
      border-radius: 5px;
      pointer-events: none;
      .inner-bar {
        &.active {
        transition: var(--slide-duration) linear;
        width: 100%;
        }
      }
    }
  }
}