.backdrop {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-wrapper {
  width: 45rem;
  padding: 3rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--light-bg-color);
  border-radius: 3px;
}