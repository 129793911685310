.reset {
  background: #1c1c1c;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  img {
    height: 43%;
    transition: .3s ease;
  }
  &:hover {
    img {
      transform: scale(1.1)
    }
  }
}