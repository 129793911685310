.title {
  font-size: 24px;
  font-family: "Playfair", serif;
}

.text-small {
  font-size: 11px;
  font-weight: 700;
}

.text-normal {
  font-size: 14px;
  font-weight: 400;
}


.wrapper {
  width: 100%;
  padding-bottom: 140%;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  background: var(--navbar-color);

  .content-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 7px;
    display: flex;
    flex-direction: column;
  }

  .image-holder {
    width: 100%;
    border-radius: 3px;
    background: #2C2C2C;
    position: relative;
    overflow: hidden;
    padding-bottom: 60%;
    .image-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    svg {
      width: 25%;
    }

    .times {
      position: absolute;
      bottom: 7px;
      right:  7px;
      display: flex;
      gap: 5px;

      .time {
        background: #1C1C1C;
        padding: 6px 10px;
        border-radius: 2px;
      }
    }
  }

  .title {
    margin-top: 1rem;
  }

  .categories {
    margin-top: 4px;
    display: flex;
    gap: 5px;

    .category {
      padding: 4px 6px;
      background: var(--main-red-color);
      border-radius: 2px;
    }
  }

  .description-holder {
    margin-top: 1rem;
    flex: 1 1 auto;
  }

  .service-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &.justify-end {
      justify-content: flex-end;
    }

    .coaches {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .text-price{
      padding-left: 10%;
    }

  }
}

@media screen and (max-width: 1700px) {
  .title {
    font-size: 20px;
  }

  .text-normal {
    font-size: 12px;
  }

}

@media screen and (max-width: 1550px) {
  .title {
    font-size: 24px;
  }

  .text-normal {
    font-size: 14px;
  }

}

@media screen and (max-width: 550px) {
  .title {
    font-size: 24px;
  }

  .text-small {
    font-size: 14px;
  }

}


// shine
.holder {
  position: relative;
  width: fit-content;
  overflow: hidden;
  border-radius: 2px;
  background: #2c2c2c;
  &.title-holder {
    margin-top: 1rem;
    .title {
      margin-top: 0;
      color: #2c2c2c;
    }
  }
}
.loading {
  display: flex;flex-direction: column;
  gap: 5px;
  .holder {
    flex: unset;
  }
  p {
    color: #2c2c2c;
  }
}

.categories {
  .holder {
    border-radius: 2px;
    .category {
      background: #2c2c2c;
      p {
        color: #2c2c2c;
      }
    }
  }
}

.service-row {
  .holder {
    p {
      color: #2c2c2c;
    }
    .text-price {
      margin-top: 0;
      padding-left: 0;
    }
  }
}