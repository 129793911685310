@import "assets/webfonts/css/webfonts";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

button {
  outline: none;
  background: none;
  border: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 140%;
}

#overlays {
  position: fixed;
  z-index: 20;
}
// colors setup
:root {
  --navbar-color: #1C1C1C;
  --light-text-color: #FCF3F3;
  --dark-text-color: #340F0F;
  --dimmed-text-color: rgba(252, 243, 243, 0.60);
  --semi-dimmed-text-color: rgba(252, 243, 243, 0.80);
  --main-red-color: #B53436;
  --dimmed-red-color: rgba(181, 52, 54, 0.60);
  --dark-bg-color: #121212;
  --light-bg-color: #FCF3F3;
  --gray-copyright-color: #494949;
}

// fonts setup
p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: var(--light-text-color);
  text-decoration: none;
  width: fit-content;
}

h1, h2, h3, h4, h5, li, a {
  font-family: "Playfair", serif;
  font-weight: 700;
  text-decoration: none;
  color: var(--light-text-color);
  width: fit-content;
}

// content and component classes
.classic-content {
  width: 85%;
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.viewport-section {
  width: 100%;
  height: clamp(50rem, 100vh, 71rem);
}

.classic-button {
  height: clamp(40px, 3.125rem, 50px);
  padding: 0 1rem;
}

.square-button {
  width: clamp(40px, 3.125rem, 50px);
  height: clamp(40px, 3.125rem, 50px);
  min-width: clamp(40px, 3.125rem, 50px);
  min-height: clamp(40px, 3.125rem, 50px);
  padding: 0 1rem;
}
.light-scrollbar {
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--light-bg-color) var(--navbar-color);
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: var(--navbar-color);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--light-bg-color);
    border-radius: 0;
    border: 0 solid var(--light-bg-color);
  }
}
.dark-scrollbar {
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--dark-bg-color) var(--light-bg-color);
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: var(--light-bg-color);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--dark-bg-color);
    border-radius: 0;
    border: 0 solid var(--dark-bg-color);
  }
}

.introduction {
  padding: 4rem 0 5rem;
}



// font sizes
html {
  font-size: 16px;
  background: var(--dark-bg-color);
}

.main-heading {
  font-size: clamp(32px, 4rem, 64px);
}

.main-desc {
  font-size: clamp(14px, 1.25rem, 20px);
}

.filter-text {
  //font-size:clamp(12px, 0.875rem, 14px);
  font-size: 14px;
}

@media screen and (max-width: 1700px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-width: 1550px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 1300px) {
  html {
    font-size: 13px;
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 11px;
  }
  .classic-content {
    width: 100%;
    max-width: 750px;
    padding: 0 16px;
  }
  .viewport-section {
    min-height: unset;
    height: auto;
  }
}

@media screen and (max-width: 640px) {
  .main-heading {
    font-size: 32px;
  }
  html {
    font-size: 10px;
  }
}

// shine effect
/* Shine */
.shine:after {
  content:'';
  top:0;
  transform:translateX(100%);
  width:100%;
  height:100%;
  position: absolute;
  z-index:1;
  animation: slide 1s infinite;

  /*
  CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/
  */
  background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(79, 79, 79, 0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%, rgba(79, 79, 79, 0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(79, 79, 79, 0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(79, 79, 79, 0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(79, 79, 79, 0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* IE10+ */
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(79, 79, 79, 0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

/* animation */

@keyframes slide {
  0% {transform:translateX(-100%);}
  100% {transform:translateX(100%);}
}