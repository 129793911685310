
.services {
  padding-bottom: 6rem;
  min-height: 80vh;
}

.filter-row {
  display: flex;
  gap: #{"max(7px,0.7rem)"};

  &.mobile {
    display: none;
  }
  button {
    font-size: 16px;
    transition: .3s ease;
    font-weight: 700;
  }
  .cancel-button {
    border: 1px solid var(--main-red-color);
    color: var(--main-red-color);
    &:hover {
      box-shadow: 0 0 5px var(--main-red-color);
    }
  }
  .confirm-button {
    background: var(--light-bg-color);
    color: var(--dark-text-color);
    &:hover {
      box-shadow: 0 0 5px var(--light-bg-color);
    }
  }
}


.services-grid {
  margin-top: #{"max(7px,0.7rem)"};
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: #{"max(7px,0.7rem)"};
}

@media screen and (max-width: 1550px) {
  .services-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1300px) {
  .services-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .filter-row {
    width: 100%;
    position: relative;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@media screen and (max-width: 640px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
  .filter-row{
    width: calc(100% + 32px);
    padding: 0 16px;
    left: -16px;
  }
}
