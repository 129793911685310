.input {
  width: 100%;
  height: 40px;
  padding: 0 1rem;
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(52, 15, 15, 0.40);
  transition: .3s ease;
  font-size: 14px;
  color: var(--dark-text-color);
  font-family: "Lato", sans-serif;
  &::placeholder {
    color: rgba(52, 15, 15, 0.4);
  }
  &:focus {
    border-bottom: 1px solid rgba(52, 15, 15, 1);
  }
  &.valid {
    border-bottom: 1px solid rgba(52, 15, 15, 1);
  }
}