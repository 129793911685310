nav {
  width: 100%;
  height: 5rem;
  background: var(--navbar-color);
  top: 0;
  left: 0;
  position: relative;
  z-index: 10;
  &.fixed {
    position: fixed;
  }
}

a {
  cursor: pointer;
}

.logout {
  color: var(--main-red-color);
  font-family: "Lato", sans-serif;
  border: 1px solid var(--main-red-color);
  transition: .3s ease;
  font-size: 1.25rem;
  font-weight: 700;
  &:hover {
    box-shadow: 0 0 3px var(--main-red-color);
  }
}

.navigation-content {
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menu-logo {
  height: 70%;
  img {
    height: 100%;
  }
}

.menu-items {
  display: flex;
  align-items: center;
  gap: 6rem;
  list-style-type: none;

  li {
    display: flex;
  }

  a {
    color: var(--light-text-color);
    opacity: .6;
    transition: .3s ease;
    font-size: 1.25rem;

    &.active {
      opacity: 1;
      pointer-events: none;
    }

    &.marked {
      position: relative;

      &::before {
        top: 50%;
        content: "";
        right: calc(100% + .5rem);
        position: absolute;
        display: block;
        background: var(--main-red-color);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: translateY(-50%);
      }
    }

    &:hover {
      opacity: .8;
    }
  }
}

@media screen and (max-width: 1024px) {
  .menu-items {
    gap: 5rem;
  }
}

@media screen and (max-width: 640px) {
  nav {
    height: 55px;
    .menu-button {
      outline: none;
      background: none;
      border: none;
      display: flex;
      flex-direction: column;
      gap: 5px;
      height: 19px;
      width: 30px;
      position: relative;

      span {
        width: 100%;
        height: 3px;
        border-radius: 1px;
        background: white;
        position: absolute;
        left: 0;
        transition: .3s ease;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: 50%;
          transform: translateY(-50%);
        }

        &:nth-child(3) {
          bottom: 0;
        }

      }
    }

    &.opened {
      .menu-button {
        span {
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(1) {
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
          }
          &:nth-child(3) {
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
          }
        }
      }
    }
  }
  .mobile-menu-holder {
    position: fixed;
    top: 55px;
    left: 0;
    width: 100%;
    background: var(--navbar-color);
    overflow: hidden;
    display: none;
    z-index: 10;

    &.opened {
      display: flex;
    }
    .menu-logo {
      position: absolute;
      top: 50%;
      left: 40%;
      transform: translateY(-50%);
      height: 80%;
      opacity: .05;
    }
    .menu-content {
      height: 100%;
      justify-content: center;
      .menu-items {
        flex-direction: column;
        gap: 40px;
        align-items: flex-start;
        margin-left: 16px;
        a {
          font-size: 20px;
        }
      }
    }
  }
}
