@font-face {
  font-family: "Lato";
  src: url("../Lato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../Lato-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Playfair";
  src: url("../PlayfairDisplay-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}