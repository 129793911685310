.admin-options {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgba(18, 18, 18, 0.40);
  backdrop-filter: blur(7.5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: #{"max(7px,0.7rem)"};
  opacity: 0;
  transition: .3s ease;

  &:hover {
    opacity: 1;
  }

}

button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  border-radius: 3px;
    box-shadow: 0 0 3px rgba(255,255,255,0);
  transition: box-shadow .3s ease;
  p {
    font-weight: 700;
  }
}

.admin-button-white {
  border: 1px solid var(--light-text-color);
  &:hover {
    box-shadow: 0 0 3px var(--light-text-color);
  }
}
.admin-button-red {
  background: var(--main-red-color);
  &:hover {
    box-shadow: 0 0 5px var(--main-red-color);
  }

}